import { Typography, useMediaQuery } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";

const InfiniteScrollTable = ({
  columns = [],
  maxHeight = "65vh",
  maxWidth = "80vw",
  fetchSize = 20,
  apiUrl,
  apiParams,
  setState,
  initialState,
  enabled = false,
  filterRefresh = false,
  setEnabled = () => {},
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const tableContainerRef = useRef(null);
  const rowVirtualizerInstanceRef = useRef(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [sorting, setSorting] = useState([]);

  // Infinite query to fetch paginated data
  const { data, fetchNextPage, isError, isFetching, isLoading, hasNextPage } =
    useInfiniteQuery({
      queryKey: [
        "table-data",
        filterRefresh && [columnFilters, globalFilter, sorting],
        enabled,
      ],
      queryFn: async ({ pageParam = 1 }) => {
        const response = await apiUrl({
          ...apiParams,
          page: pageParam,
          size: fetchSize,
          columnFilters,
          globalFilter,
          sorting,
        });
        return response?.data;
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        return lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined;
      },
      onSuccess: (data) => {
        setEnabled(false);
      },
      onError: (error) => {
        setEnabled(false);
        toast.error(error?.response?.data?.message ?? "Error loading data");
      },
      refetchOnWindowFocus: false,
    });

  const flatData = useMemo(
    () => (data?.pages ? data.pages.flatMap((page) => page.list) : []),
    [data]
  );

  // Function to fetch more data when reaching the bottom of the table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement && hasNextPage) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < 100 && !isFetching) {
          // Trigger next page fetch
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  // Reset the scroll position when filters or sorting are changed
  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting, columnFilters, globalFilter]);

  // Attach scroll listener to fetch more data
  useEffect(() => {
    const containerRefElement = tableContainerRef.current;
    if (containerRefElement) {
      const onScroll = () => fetchMoreOnBottomReached(containerRefElement);
      containerRefElement.addEventListener("scroll", onScroll);
      return () => containerRefElement.removeEventListener("scroll", onScroll);
    }
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    if (setState) {
      setState((prev) => ({
        ...prev,
        tableData: flatData,
      }));
      setPage(data?.pages?.length ?? 1);
      setPages(data?.pages[0]?.pages ?? 1);
    }
  }, [data?.pages, flatData, setState]);

  const table = useMaterialReactTable({
    columns,
    data: flatData,
    enableColumnPinning: true,
    enableStickyHeader: true,
    enablePagination: false,
    enableRowNumbers: false,
    enableTopToolbar: true,
    enableRowVirtualization: true,
    enableBottomToolbar: true,
    manualFiltering: true,
    manualSorting: true,
    columnVirtualizerOptions: { overscan: 4 },
    initialState: initialState, //for column pinning
    muiTableProps: {
      sx: {
        tableLayout: isMobile ? "auto" : "fixed",
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 0,
        border: "1px solid #B6C8D6",
        borderRadius: "10px",
      },
    },
    muiTopToolbarProps: {
      sx: {
        borderRadius: "10px 10px 0 0",
        backgroundColor: "#f6f8fc",
      },
    },
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: {
        maxHeight: maxHeight,
        overflowY: "auto",
        borderRadius: "0 0 10px 10px",
        "&::-webkit-scrollbar": {
          width: 10,
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
          border: "1px solid #48657c",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#465f72",
          borderRadius: "10px",
        },
      },
      // onScroll: is handled through useEffect
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: isMobile ? ".9rem" : "12px",
        color: "#0C2F49",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontWeight: "normal",
        fontSize: isMobile ? "1rem" : "14px",
        color: "#0C2F49",
        textAlign: "center",
        marginX: "auto",
        marginY: "auto",
      },
    },
    muiTableHeadRowProps: {
      sx: {
        height: "36px",
        width: "100%",
        backgroundColor: "#F6F8FC",
      },
    },
    enableColumnFilters: true,
    muiTableBodyProps: {
      sx: {
        fontSize: "14px",
      },
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    renderBottomToolbarCustomActions: () => (
      <Typography>
        {/* showing page from the total pages */}
        Showing page {page ?? 0} of pages {pages ?? 0}
      </Typography>
    ),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      // showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
    },
    rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 4 },
    ...props,
  });

  return <MaterialReactTable table={table} />;
};

export default InfiniteScrollTable;
