import { Grid, Typography } from "@mui/material";
import { FormatFullName } from "app/utils/helper";
import moment from "moment";
import React from "react";

const StaffSearchCard = ({ item, state, setState }) => {
  return (
    <Grid
      container
      spacing={2}
      display={"flex"}
      direction={"row"}
      alignItems={"center"}
      sx={{
        cursor: "pointer",
        color: state?.selectedStaff === item ? "blue" : "black",
        ":hover": {
          backgroundColor: "#f5f5f5",
        },
      }}
      onClick={() => {
        setState((prev) => ({
          ...prev,
          selectedStaff: item,
          menuOpen: false,
        }));
      }}
    >
      <Grid item xs={3}>
        <img
          src={item?.profilePic ?? "./assets/images/avatars/1.jpg"}
          alt="profile"
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
      </Grid>
      <Grid item xs={9}>
        <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
          {item?.firstName
            ? FormatFullName(item?.firstName, item?.middleName, item?.lastName)
            : "-"}{" "}
          {item?.primaryRole ? (
            <span style={{ color: "blue" }}> ({item?.primaryRole})</span>
          ) : (
            "-"
          )}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: "10px" }}>
          Father:-{" "}
          {item?.fatherInfo?.firstName
            ? FormatFullName(
                item?.fatherInfo?.firstName,
                item?.fatherInfo?.middleName,
                item?.fatherInfo?.lastName
              )
            : "-"}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: "10px" }}>
          Des.:- {item?.designation}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: "10px" }}>
          Department:- {item?.departmentName}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "10px",
          }}
        >
          Gender:- {item?.gender ?? "-"}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "10px",
          }}
        >
          DOB:- {moment(item?.dob).format("DD-MM-YYYY") ?? "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StaffSearchCard;
