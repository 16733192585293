import CommonDialog from "app/components/common/CommonDialog";
import FilterButton from "app/components/common/FilterButton";
import React, { useState } from "react";
import CommonFilterDialog from "../CommonFilterDialog";
import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  CircularProgress,
  Divider,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { FormatFullName, MenuProps } from "app/utils/helper";
import { Distrct, States } from "app/config/administrativeConstants";
import CustomChip from "app/components/common/CustomChip";
import { DatePicker } from "@mui/x-date-pickers";
import { getSchoolByName } from "app/services/org/school.service";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getDistinctActiveSessionDropdown,
  getEkalsutraStaff,
} from "app/services/school";
import StaffSearchCard from "app/components/Tables/StaffSearchCard";

const InstituteListFilter = ({ mutate = () => {} }) => {
  const [state, setState] = useState({
    filterDialog: false,
    schoolName: "",
    schoolDocIdList: [],
    page: 1,
    menuOpen: false,
    schoolData: {},
    selectedStaff: {},
  });
  const [filterState, setFilterState] = useState({
    checkboxes: {
      district: false,
      createdBy: false,
      createdOn: true,
      status: false,
      schoolName: false,
      activeSession: false,
    },
    state: "",
    district: "",
    createdBy: "",
    from: new Date(),
    to: new Date(),
    status: "",
    activeSession: "",
  });
  const {
    isFetching: getSchoolByNameFetching,
    isLoading: getSchoolByNameLoading,
  } = useQuery({
    queryKey: ["getStudentByName", state.schoolName, state.page],
    queryFn: () =>
      getSchoolByName({
        session: state.session,
        schoolName: state.schoolName,
        page: state.page,
      }),
    onSuccess: (data) => {
      if (state.page !== 1) {
        setState((prev) => ({
          ...prev,
          schoolData: { ...prev.schoolData, ...data?.data },
        }));
      } else {
        setState((prev) => ({ ...prev, schoolData: data?.data }));
      }
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: state.schoolName ? true : false,
  });

  const {
    isLoading: getEkalsutraStaffLoading,
    isFetching: getEkalsutraStaffIsFetching,
    data: getEkalsutraStaffData,
  } = useQuery({
    queryKey: ["getEkalsutraStaff"],
    queryFn: () => getEkalsutraStaff(),
    onSuccess: (success) => {},
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { data: getDistinctActiveSessionDropdownData } = useQuery({
    queryKey: ["getDistinctActiveSessionDropdown"],
    queryFn: () => getDistinctActiveSessionDropdown(),
    onSuccess: (success) => {},
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const handleFilter = () => {
    mutate({
      ...(filterState.checkboxes.createdOn && {
        from: filterState.from,
        to: filterState.to,
      }),
      ...(filterState.checkboxes.district &&
        filterState.state && {
          dist: filterState.district,
        }),
      ...(filterState.checkboxes.activeSession &&
        filterState.activeSession && {
          activeSession: filterState.activeSession,
        }),
      ...(filterState.checkboxes.createdBy && {
        createdByPersonDocId: filterState.createdBy?.staffDocId,
      }),
      ...(filterState.checkboxes.status && { accountStatus: state.status }),
      ...(filterState.checkboxes.schoolName && {
        reqSchoolDocIdList: JSON.stringify(
          state.schoolDocIdList?.map((item) => item?.schoolDocId)
        ),
      }),
    });
    setState((prev) => ({ ...prev, filterDialog: false }));
  };
  const handleOnClickCheckBoxes = (name) => {
    setFilterState((prev) => ({
      ...prev,
      checkboxes: {
        ...prev.checkboxes,
        [name]: !prev.checkboxes[name],
      },
    }));
  };
  return (
    <>
      <FilterButton
        count={
          Object.values(filterState.checkboxes).filter((item) => item === true)
            .length
        }
        handleClick={() =>
          setState((prev) => ({ ...prev, filterDialog: true }))
        }
      />
      <CommonFilterDialog
        handleSaveAndView={handleFilter}
        open={state.filterDialog}
        onClose={() => setState((prev) => ({ ...prev, filterDialog: false }))}
        minWidth="500px"
      >
        <DialogContent>
          {/* district checkbox */}
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterState.checkboxes.district}
                  onChange={() => handleOnClickCheckBoxes("district")}
                  color="secondary"
                />
              }
              label={"District"}
            />
            {filterState.checkboxes.district && (
              <>
                <Grid container spacing={1.5} sx={{ pt: 1.3 }}>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      select
                      value={filterState.state}
                      label="Select State"
                      onChange={(e) =>
                        setFilterState((prev) => ({
                          ...prev,
                          state: e.target.value,
                        }))
                      }
                      fullWidth
                      size="small"
                      SelectProps={{
                        MenuProps,
                      }}
                    >
                      {States.map((state, index) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      disabled={!filterState.state}
                      select
                      value={filterState.district}
                      onChange={(e) =>
                        setFilterState((prev) => ({
                          ...prev,
                          district: e.target.value,
                        }))
                      }
                      label="Select District"
                      fullWidth
                      size="small"
                      SelectProps={{
                        MenuProps,
                      }}
                    >
                      {Distrct[
                        States.indexOf(String(filterState.state)) + 1
                      ].map((district) => (
                        <MenuItem key={district} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          {/* created By */}
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterState.checkboxes.createdBy}
                  onChange={() => handleOnClickCheckBoxes("createdBy")}
                  color="secondary"
                />
              }
              label={"Created By"}
            />
            {filterState.checkboxes.createdBy && (
              <>
                <Grid container spacing={1.5} sx={{ pt: 1.3 }}>
                  <Grid item xs={12} md={6}>
                    <CustomSelectBox
                      displayEmpty
                      fullWidth
                      size="small"
                      id="createdBy"
                      MenuProps={MenuProps}
                      value={filterState.createdBy}
                      name="createdBy"
                      onChange={(e) => {
                        console.log("e", e.target.value);
                        setFilterState((prev) => ({
                          ...prev,
                          createdBy: e.target.value,
                        }));
                      }}
                      renderValue={(selected) => {
                        if (selected) {
                          return (
                            <Typography>
                              {FormatFullName(
                                filterState?.createdBy?.firstName,
                                filterState?.createdBy?.middleName,
                                filterState?.createdBy?.lastName
                              )}
                            </Typography>
                          );
                        }
                        return <Typography>Select</Typography>;
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {getEkalsutraStaffData?.data?.list?.map((item) => (
                        <MenuItem key={item} value={item}>
                          <StaffSearchCard
                            item={item}
                            state={state}
                            setState={setState}
                          />
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          {/* created on */}
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterState.checkboxes.createdOn}
                  onChange={() => handleOnClickCheckBoxes("createdOn")}
                  color="secondary"
                />
              }
              label={"Created On"}
            />
            {filterState.checkboxes.createdOn && (
              <>
                <Grid container spacing={1.5} sx={{ pt: 1.3 }}>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      onChange={(e) =>
                        setFilterState((prev) => ({
                          ...prev,
                          from: e,
                        }))
                      }
                      value={filterState.from}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          size="small"
                          fullWidth
                          label="From"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      onChange={(e) =>
                        setFilterState((prev) => ({
                          ...prev,
                          to: e,
                        }))
                      }
                      value={filterState.to}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          size="small"
                          fullWidth
                          label="To"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          {/* activeSession */}
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterState.checkboxes.activeSession}
                  onChange={() => handleOnClickCheckBoxes("activeSession")}
                  color="secondary"
                />
              }
              label={"Active Session"}
            />
            {filterState.checkboxes.activeSession && (
              <>
                <Grid container sx={{ pt: 1.3 }}>
                  <Grid item xs={12}>
                    <CustomSelectBox
                      fullWidth
                      displayEmpty
                      value={filterState.activeSession}
                      onChange={(e) =>
                        setFilterState((prev) => ({
                          ...prev,
                          activeSession: e.target.value,
                        }))
                      }
                      size="small"
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Select</em>
                      </MenuItem>
                      {getDistinctActiveSessionDropdownData?.data?.list?.map(
                        (item, idx) => (
                          <MenuItem value={item} key={idx}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    </CustomSelectBox>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterState.checkboxes.status}
                  onChange={() => handleOnClickCheckBoxes("status")}
                  color="secondary"
                />
              }
              label={"Status"}
            />
            {filterState.checkboxes.status && (
              <>
                <Box>
                  {filterState.checkboxes.status && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <CustomSelectBox
                          fullWidth
                          displayEmpty
                          value={state.status}
                          size="small"
                          MenuProps={MenuProps}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }))
                          }
                        >
                          <MenuItem disabled value="">
                            <em>Select</em>
                          </MenuItem>
                          <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                          <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                        </CustomSelectBox>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </>
            )}
          </Box>

          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={filterState.checkboxes.schoolName}
                  onChange={() => handleOnClickCheckBoxes("schoolName")}
                />
              }
              label="By Name"
            />
            {filterState.checkboxes.schoolName && (
              <Box>
                {filterState.checkboxes.schoolName && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <CustomSelectBox
                          size="small"
                          sx={{ width: "250px", backgroundColor: "#fff" }}
                          MenuProps={MenuProps}
                          multiple
                          value={state.schoolDocIdList}
                          fullWidth
                          open={state.menuOpen}
                          onOpen={() =>
                            setState((prev) => ({ ...prev, menuOpen: true }))
                          }
                          onClose={() =>
                            setState((prev) => ({ ...prev, menuOpen: false }))
                          }
                          placeholder="Select"
                          onChange={(e) => e.stopPropagation()}
                          renderValue={() => {
                            return <Typography>Select</Typography>;
                          }}
                        >
                          <Box sx={{ px: 1 }}>
                            <CustomTextField
                              autoFocus
                              sx={{ mb: 0.5 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {(getSchoolByNameFetching ||
                                      getSchoolByNameLoading) && (
                                      <CircularProgress size={20} />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Type School Name"
                              onChange={(e) => {
                                console.log(e.target.value, "in value");
                                e.stopPropagation();
                                setState((prev) => ({
                                  ...prev,
                                  schoolName: e.target.value,
                                  ...(state.page !== 1 && { page: 1 }),
                                  ...(!e.target.value && { schoolData: {} }),
                                }));
                              }}
                              value={state.schoolName}
                              fullWidth
                              size="small"
                            />
                            {state.schoolData?.list?.map((item, ind) => (
                              <Box key={ind}>
                                <Grid
                                  container
                                  spacing={1}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Grid item xs={10}>
                                    <Typography
                                      sx={{ fontWeight: 600, fontSize: "14px" }}
                                    >
                                      {item?.schoolName}
                                    </Typography>
                                    <Typography
                                      sx={{ fontWeight: 500, fontSize: "12px" }}
                                    >
                                      School Code:- {item?.schoolCode}
                                    </Typography>
                                    <Typography
                                      sx={{ fontWeight: 500, fontSize: "12px" }}
                                    >
                                      License No:- {item?.ekalRefNumber}
                                    </Typography>
                                    <Typography
                                      sx={{ fontWeight: 500, fontSize: "12px" }}
                                    >
                                      Account Status:- {item?.accountStatus}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Checkbox
                                      color="secondary"
                                      checked={state.schoolDocIdList
                                        .map((id) => id.schoolDocId)
                                        .includes(item?.schoolDocId)}
                                      onChange={() => {
                                        if (
                                          state.schoolDocIdList
                                            .map((id) => id.schoolDocId)
                                            .includes(item?.schoolDocId)
                                        ) {
                                          state.schoolDocIdList =
                                            state.schoolDocIdList.filter(
                                              (res) =>
                                                res?.schoolDocId !==
                                                item?.schoolDocId
                                            );
                                        } else {
                                          state.schoolDocIdList.push(item);
                                        }
                                        // if (!button) {
                                        //   setSelectedStudent(
                                        //     state.schoolDocIdList.map(
                                        //       (res) => res.studentDocId
                                        //     )
                                        //   );
                                        // }
                                        setState((prev) => ({ ...prev }));
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <Divider
                                  sx={{
                                    my: 0.5,
                                    backgroundColor: "#3b3939",
                                  }}
                                />
                              </Box>
                            ))}
                            {state.schoolData?.pages !==
                              state.schoolData?.page && (
                              <Stack
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Typography
                                  sx={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    textAlign: "center",
                                    display: "inline-block",
                                    mb: 1,
                                  }}
                                  onClick={() =>
                                    setState((prev) => ({
                                      ...prev,
                                      page: prev.page + 1,
                                    }))
                                  }
                                >
                                  more
                                </Typography>
                              </Stack>
                            )}
                          </Box>
                        </CustomSelectBox>
                      </Grid>
                    </Grid>
                    <Stack direction="row" gap={1} flexWrap={"wrap"} pt={1}>
                      {state.schoolDocIdList?.map((item, ind) => (
                        <CustomChip
                          key={ind}
                          deleteIcon
                          handleClose={() => {
                            state.schoolDocIdList =
                              state.schoolDocIdList.filter(
                                (res) => res?.schoolDocId !== item?.schoolDocId
                              );
                            setState((prev) => ({ ...prev }));
                          }}
                          title={item?.schoolName}
                        />
                      ))}
                    </Stack>
                  </>
                )}
              </Box>
            )}
          </Stack>
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default InstituteListFilter;
