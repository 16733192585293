import { axiosInstance } from "../axios";
// const AUTH_URL = process.env.REACT_APP_AUTH_BASE;
// const ORG_URL = process.env.REACT_APP_ORG_BASE;
const APP_SMS = process.env.REACT_APP_SMS_BASE;

/////Code written By Rahul
/// I am making Axious handelr for the
export const getSenderID = (params) => {
  return axiosInstance.get(`${APP_SMS}/ekalAdmin/getAllSenderId`, { params });
};
