import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { changeDueDateOfSchool } from "app/services/org/collection.service";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const ChangeDuesDateDialog = ({
  state,
  setState,
  handleCloseChangeDueDateDialog,
  handleRefetch = () => {},
}) => {
  const { mutate, isLoading: changeDueDateOfSchoolLoading } = useMutation(
    changeDueDateOfSchool,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        handleRefetch();
        handleCloseChangeDueDateDialog();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleMutateChanDueDate = () => {
    if (!state.newDueDate) {
      toast.warning("Current and New Due Date can't be same");
      return;
    }
    mutate({
      schoolDocId: state.selectedItemSchoolDocId,
      upcomingDueDate: state.newDueDate,
    });
  };
  return (
    <>
      <Dialog
        open={state.changeDueDateDialog}
        onClose={handleCloseChangeDueDateDialog}
        PaperProps={{
          sx: { minWidth: "20vw" },
        }}
      >
        <DialogContent>
          <Stack direction="row" gap={2}>
            <Stack flex={1}>
              <TextFieldLabel title="Current Due Date" />
              <DesktopDatePicker
                inputFormat="DD-MM-YYYY"
                name="currentDueDate"
                value={state.currentDueDate}
                renderInput={(params) => (
                  <CustomTextFieldDisabled
                    {...params}
                    placeholder="Select"
                    size="small"
                  />
                )}
              />
            </Stack>

            <Stack flex={1}>
              <TextFieldLabel title="New DueDate" />
              <DesktopDatePicker
                inputFormat="DD-MM-YYYY"
                name="newDueDate"
                value={state.newDueDate}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    newDueDate: e,
                  }));
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    placeholder="Select"
                    size="small"
                    required
                  />
                )}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={changeDueDateOfSchoolLoading}
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mt: 1, width: "40%" }}
            onClick={() =>
              // mutate({
              //   schoolDocId: state.selectedItemSchoolDocId,
              //   upcomingDueDate: state.newDueDate,
              // })
              handleMutateChanDueDate()
            }
          >
            UPDATE
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeDuesDateDialog;
