import {
  Box,
  Button,
  Checkbox,
  Container,
  DialogContent,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import DeleteDialog from "app/components/Dialogs/DeleteDialog";
import CommonTablePagination from "app/components/Tables/CommonTablePagination";
import CommonDialog from "app/components/common/CommonDialog";
import CustomLoader from "app/components/common/CustomLoader";
import appRoutes from "app/config/routes";
import {
  deleteIdCardFrame,
  getTotalIdCardFrame,
} from "app/services/org/reporting.service";
import useSelectorHook from "app/services/redux/useSelectorHook";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import VerticalIdCard from "../components/VerticalIdCard";
import HorizontalCard from "../components/HorizontalCard";
import FilterButton from "app/components/common/FilterButton";
import CommonFilterDialog from "app/components/Dialogs/CommonFilterDialog";
import VerticalStaffIdCard from "../components/VerticalStaffIdCard";
import HorizontalStaffIdCard from "../components/HorizontalStaffIdCard";
import InfiniteScrollTable from "app/components/Tables/InfiniteScrollTable";

const IDCardTablePage = () => {
  const [checkboxes, setCheckboxes] = useState({
    staff: false,
    student: false,
  });
  const [state, setState] = useState({
    list: [],
    addNewDialog: false,
    viewFrameDialog: false,
    page: 1,
    pages: 1,
    deleteDialog: false,
    deleteId: null,
    frameSpecificData: null,
    dialog: false,
  });
  const { selectedSession } = useSelectorHook();
  const [enabled, setEnabled] = useState(false);
  const {
    isFetching: getTotalIdCardFrameFetching,
    isLoading: getTotalIdCardFrameLoading,
    data: getTotalIdCardFrame_Data,
    refetch: getTotalIdCardFrameRefetch,
  } = useQuery({
    queryKey: ["getTotalIdCardFrame"],
    queryFn: () => {
      if (checkboxes) {
        return getTotalIdCardFrame({
          for: checkboxes?.staff
            ? "Staff"
            : checkboxes?.student
            ? "Student"
            : "",
        });
      } else {
        return getTotalIdCardFrame();
      }
    },
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, list: data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: true,
  });

  const {
    mutate: deleteIdCardFrameMutate,
    isLoading: deleteIdCardFrameLoading,
  } = useMutation(deleteIdCardFrame, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      getTotalIdCardFrameRefetch();

      setState((prev) => ({
        ...prev,
        deleteDialog: false,
        deleteId: null,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>{parseInt(cell.row.id) + 1}</Typography>
        ),
        maxSize: 20,
      },
      {
        header: "Frame Code",
        accessorKey: "frameCode",
        maxSize: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.frameCode}</Typography>,
      },
      {
        header: "Size",
        accessorKey: "size",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.width} x {original?.height}
          </Typography>
        ),
        maxSize: 30,
      },

      {
        header: "Orientation",
        accessorKey: "orientation",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.orientation}</Typography>,

        maxSize: 30,
      },
      {
        header: "Theme Color",
        accessorKey: "themeColor",
        maxSize: 30,
      },
      {
        header: "Size For",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.sizeFor}</Typography>,
        maxSize: 30,
      },

      {
        header: "Created On",
        accessorKey: "createdOn",
        Cell: ({ cell, row }) => {
          return (
            <Stack direction="row" gap={0.5}>
              <Typography>
                {moment(row.original?.createdOn).format("LL")}
              </Typography>
            </Stack>
          );
        },
        maxSize: 20,
      },
      //   {
      //     header: "Updated On",
      //     accessorKey: "updatedOn",
      //     Cell: ({ cell, row }) => {
      //       return (
      //         <Stack direction="row" gap={0.5}>
      //           <Typography>
      //             {moment(row.original?.updatedOn).format("LT")}
      //           </Typography>
      //         </Stack>
      //       );
      //     },
      //     maxSize: 20,
      //   },

      {
        header: "Actions",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Stack direction={"row"} gap={0.8} alignItems={"center"}>
              <Box
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    viewFrameDialog: true,
                    frameSpecificData: original,
                  }))
                }
                sx={{ cursor: "pointer" }}
              >
                <img
                  alt="view"
                  src="/view-icon.png"
                  style={{
                    height: "28px",
                    width: "28px",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Link
                to={
                  original?.for === "Staff"
                    ? `../../${appRoutes.customStaffIdCard}/${String(
                        original?.frameCode
                      ).replace(/\//g, " ")}`
                    : `../../${appRoutes.customIdCard}/${String(
                        original?.frameCode
                      ).replace(/\//g, " ")}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box sx={{ cursor: "pointer" }} onClick={() => {}}>
                  <img
                    alt="edit"
                    src="/edit-icon.png"
                    style={{
                      height: "28px",
                      width: "28px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Link>

              <Box
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    deleteDialog: true,
                    deleteId: original?._id,
                  }));
                }}
                sx={{ cursor: "pointer" }}
              >
                <img
                  alt="delete"
                  src="/delete-icon.png"
                  style={{
                    height: "28px",
                    width: "28px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Stack>
          );
        },
        maxSize: 40,
      },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>ID Card</title>
      </Helmet>
      <CustomLoader
        show={getTotalIdCardFrameFetching || getTotalIdCardFrameLoading}
      />

      {/* filter dialog */}
      <CommonFilterDialog
        open={state.dialog}
        onClose={() => {
          setState((prev) => ({ ...prev, dialog: false }));
        }}
        minWidth="400px"
        handleSaveAndView={() => {
          setState((prev) => ({ ...prev, dialog: false }));
          getTotalIdCardFrameRefetch();
        }}
      >
        <DialogContent>
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.staff}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  staff: !prev.staff,
                  student: false,
                }))
              }
              componentsProps={{
                typography: {
                  sx: { fontSize: "14px", fontWeight: 600 },
                },
              }}
              label="For Staff"
            />
          </Box>
          <Box mt={1.5}>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.student}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  student: !prev.student,
                  staff: false,
                }))
              }
              componentsProps={{
                typography: {
                  sx: { fontSize: "14px", fontWeight: 600 },
                },
              }}
              label="For Student"
            />
          </Box>
        </DialogContent>
      </CommonFilterDialog>

      {/* View dialog for ID Card*/}
      <CommonDialog
        open={state?.viewFrameDialog}
        handleClose={() => {
          setState((prev) => ({ ...prev, viewFrameDialog: false }));
        }}
        onClose={() =>
          setState((prev) => ({ ...prev, viewFrameDialog: false }))
        }
        minWidth="60%"
        minHeight="80%"
        title={"Frame Details"}
      >
        {state?.frameSpecificData && (
          <Stack spacing={2} alignSelf={"center"}>
            {state?.frameSpecificData?.orientation === "portrait" &&
              state?.frameSpecificData?.for === "Staff" && (
                <VerticalStaffIdCard
                  data={state?.frameSpecificData}
                  value={"mainIDFormat"}
                  idCardDetailLocation={state?.frameSpecificData}
                />
              )}
            {state?.frameSpecificData?.orientation === "portrait" &&
              state?.frameSpecificData?.for === "Student" && (
                <VerticalIdCard
                  data={state?.frameSpecificData}
                  value={"mainIDFormat"}
                  idCardDetailLocation={state?.frameSpecificData}
                />
              )}
            {state?.frameSpecificData?.orientation === "landscape" &&
              state?.frameSpecificData?.for === "Student" && (
                <HorizontalCard
                  data={state?.frameSpecificData}
                  value={"mainIDFormat"}
                  idCardDetailLocation={state?.frameSpecificData}
                />
              )}
            {state?.frameSpecificData?.orientation === "landscape" &&
              state?.frameSpecificData?.for === "Staff" && (
                <HorizontalStaffIdCard
                  data={state?.frameSpecificData}
                  value={"mainIDFormat"}
                  idCardDetailLocation={state?.frameSpecificData}
                />
              )}
          </Stack>
        )}
      </CommonDialog>

      <DeleteDialog
        open={state.deleteDialog}
        handleClose={() => {
          setState((prev) => ({ ...prev, deleteDialog: false }));
        }}
        handleDelete={() => {
          deleteIdCardFrameMutate(state.deleteId);
        }}
        loading={deleteIdCardFrameLoading}
      />

      {/* screen view of this page */}
      <Container maxWidth="xl">
        <Stack
          sx={{ pb: 1 }}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <FilterButton
            handleClick={() => setState((prev) => ({ ...prev, dialog: true }))}
            count={
              Object.values(checkboxes).filter((item) => item === true).length
            }
          />
          <Stack direction={"row"} sx={{ gap: 1 }} alignItems={"center"}>
            <Link
              to={`../../${appRoutes.customIdCard.replace("/:frameCode", "")}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="large" variant="contained" color="secondary">
                + Add New
              </Button>
            </Link>
            <Link
              to={`../../${appRoutes?.customStaffIdCard.replace(
                "/:frameCode",
                ""
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="large" variant="contained" color="secondary">
                + Add New Staff
              </Button>
            </Link>
          </Stack>
        </Stack>

        {/* <CommonTablePagination
          columns={columns}
          data={state.list}
          totalPage={state.pages}
          page={state.page}
          setPagination={(_, count) =>
            setState((prev) => ({ ...prev, page: count }))
          }
        /> */}

        <InfiniteScrollTable
          columns={columns}
          maxHeight="60vh"
          apiUrl={getTotalIdCardFrame}
          apiParams={{
            for: checkboxes?.staff
              ? "Staff"
              : checkboxes?.student
              ? "Student"
              : "",
          }}
          setState={setState}
          enabled={enabled}
          setEnabled={setEnabled}
          filterRefresh={false} //use this to refresh the table on filter change
        />
      </Container>
    </>
  );
};

export default IDCardTablePage;
