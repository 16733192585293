import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import React from "react";
import { useMutation } from "react-query";
import { updateAccountStatus } from "app/services/school";
import { toast } from "react-toastify";
import styled from "styled-components";
import CommonDialog from "app/components/common/CommonDialog";

const AccountStatusDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  schoolDocId,
  status,
}) => {
  const { mutate: changePaymentStatus } = useMutation(updateAccountStatus, {
    onSuccess: (success) => {
      refetch();
      onClose();
      toast.success(success?.data?.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  function changeStatus(x) {
    changePaymentStatus({
      schoolDocId: schoolDocId,
      accountStatus: x,
    });
  }
  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="Account Status"
      minWidth="400px"
    >
      <DialogWrapper>
        <DialogContent sx={{ border: "0" }}>
          <Stack
            direction={"row"}
            sx={{ justifyContent: "center", padding: "25px" }}
          >
            <img width="97px" src="/payment-icon.png" alt="payment icon" />
          </Stack>
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px ",
              }}
            >
              Account Status : {status === "ACTIVE" ? "Active" : "Inactive"}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              Please select the status want to update
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box width="100%">
            <Stack
              direction={"row"}
              spacing={2}
              sx={{
                justifyContent: "space-between",
                px: 5,
                marginBottom: "35px",
              }}
            >
              <Button
                className="dialog-button"
                variant="outlined"
                color="error"
                onClick={() => changeStatus("INACTIVE")}
              >
                InActive
              </Button>
              <Button
                className="dialog-button"
                variant="contained"
                color="success"
                onClick={() => changeStatus("ACTIVE")}
              >
                Active
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </DialogWrapper>
    </CommonDialog>
  );
};

export default AccountStatusDialog;

const DialogWrapper = styled.div`
  // Dilog Box Css
  .dialog-button {
    width: 170px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;

    box-sizing: border-box;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .payment-active {
    width: 170px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(41, 219, 91, 0.4);
    background: rgba(41, 219, 91, 0.1);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: #00ac30;
  }
  .payment-inactive {
    width: 170px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(219, 41, 41, 0.4);
    background: rgba(219, 41, 41, 0.1);
    color: #ff0d02;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;
