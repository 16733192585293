import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getSchool } from "app/services/school";
import { toast } from "react-toastify";
import TableActionButtons from "app/components/common/TableActionButtons";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import CustomLoader from "app/components/common/CustomLoader";
import InstituteListFilter from "app/components/Dialogs/InstituteList/InstituteListFilter";
import dayjs from "dayjs";
import ExportDialog from "app/components/common/exportDialog";
import InfiniteScrollTable from "app/components/Tables/InfiniteScrollTable";
import AccountStatusDialog from "app/components/Dialogs/School/AccountStatusDialog";
import ChangeDuesDateDialog from "app/components/Dialogs/InstituteList/ChangeDuesDateDialog";

const InstituteList = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [state, setState] = useState({
    filterDialog: false,
    callGetSchool: false,
    dataList: [],
    filterData: false,
    fetch: false,
    changeStatusDialog: false,
    changeDueDateDialog: false,
    openChangeAccountStatus: false,
    selectedItemSchoolDocId: "",
    currentAccountStatus: "",
    currentDueDate: new Date(),
    newDueDate: new Date(),
    statusData: {},
    tableData: [],
    page: 1,
    pages: 1,
    currentPage: 1,
  });
  const [enabled, setEnabled] = useState(false);
  const handleFilter = (payload) => {
    setState((prev) => ({ ...prev, filterData: payload, fetch: !prev.fetch }));
  };
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const {
    isLoading: getSchoolLoading,
    isFetching: getSchoolIsFetching,
    refetch: getSchoolRefetch,
  } = useQuery({
    queryKey: ["getSchool", state.fetch],
    queryFn: () =>
      getSchool(
        state.filterData
          ? state.filterData
          : { from: new Date(), to: new Date() }
      ),
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      setState((prev) => ({
        ...prev,
        dataList: success?.data?.list,
        filterDialog: false,
        callGetSchool: false,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  let header = [
    "License Number",
    "School Name",
    "Director Name",
    "Due Date",
    "State",
    "District",
    "Account Status",
  ];
  let exportArray = state?.tableData?.map((item) => {
    return {
      "License Number": item?.ekalRefNumber,
      "School Name": item?.schoolName,
      "Director Name": item?.schoolAdmin?.personName,
      "Due Date": item?.upcomingDueDate
        ? `${dayjs(new Date(item?.upcomingDueDate)).format("DD-MM-YYYY")}`
        : "--",
      State: item?.schoolAddress?.state,
      District: item?.schoolAddress?.dist,
      "Account Status": item?.accountStatus,
    };
  });

  // showing table column
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>{parseInt(cell.row.id) + 1}</Typography>
        ),
        maxSize: 20,
      },
      {
        header: "License Number",
        accessorKey: "ekalRefNumber",
        maxSize: 40,
      },
      {
        header: "School Name",
        accessorKey: "schoolName",
        maxSize: 30,
      },

      {
        header: "Director Name",
        accessorKey: "director?.firstName",
        Cell: ({ cell, row }) => {
          return (
            <>
              {row?.original?.schoolAdmin ? (
                <Typography>
                  {row?.original?.schoolAdmin?.personName}
                </Typography>
              ) : (
                "---"
              )}
            </>
          );
        },
        maxSize: 30,
      },
      {
        header: "Due Date",
        accessorKey: "upcomingDueDate",
        Cell: ({ cell, row }) => {
          return (
            <>
              {row?.original?.upcomingDueDate ? (
                <Typography
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      changeDueDateDialog: true,
                      currentDueDate: row?.original?.upcomingDueDate,
                      newDueDate: row?.original?.upcomingDueDate,
                      selectedItemSchoolDocId: row?.original?._id,
                    }));
                  }}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: dayjs(
                      new Date(row?.original?.upcomingDueDate)
                    ).isBefore(new Date())
                      ? "rgba(255, 0, 0, 1)"
                      : "rgba(0, 0, 0, 1)",
                  }}
                >
                  {dayjs(new Date(row?.original?.upcomingDueDate)).format(
                    "DD-MM-YYYY"
                  )}
                </Typography>
              ) : (
                "--"
              )}
            </>
          );
        },
        maxSize: 30,
      },
      {
        header: "State",
        accessorKey: "schoolAddress?.state",
        Cell: ({ cell, row }) => {
          return (
            <>
              {row?.original?.schoolAddress ? (
                <Typography>{row?.original?.schoolAddress?.state}</Typography>
              ) : (
                "---"
              )}
            </>
          );
        },
        maxSize: 30,
      },
      {
        header: "District",
        accessorKey: "schoolAddress?.dist",
        Cell: ({ cell, row }) => {
          return (
            <>
              {row?.original?.schoolAddress ? (
                <Typography>{row?.original?.schoolAddress?.dist}</Typography>
              ) : (
                "---"
              )}
            </>
          );
        },
        maxSize: 30,
      },

      {
        header: "Account Status",
        accessorKey: "accountStatus",
        Cell: ({ cell, row }) => {
          return (
            <Stack direction="row" gap={0.5}>
              <Typography
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    openChangeAccountStatus: true,
                    currentAccountStatus: row?.original?.accountStatus,
                    selectedItemSchoolDocId: row?.original?._id,
                  }));
                }}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color:
                    row?.original?.accountStatus === "ACTIVE"
                      ? "rgba(20, 219, 28, 1)"
                      : row?.original?.accountStatus === "INACTIVE"
                      ? "rgba(255, 0, 0, 1)"
                      : "rgba(251, 177, 34, 1)",
                }}
              >
                {row?.original?.accountStatus}
              </Typography>
            </Stack>
          );
        },
        maxSize: 20,
      },

      {
        header: "Actions",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Link
              to={`/${appRoutes.singleInstituteView}?schoolDocId=${original?._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TableActionButtons
                showView
                handleView={() =>
                  setState((prev) => ({
                    ...prev,
                    viewDialog: true,
                    schoolEnquiryDocId: original?._id,
                  }))
                }
              />
            </Link>
          );
        },
        maxSize: 20,
      },
    ];
  }, []);

  const handleCloseChangeDueDateDialog = () => {
    setState((prev) => ({
      ...prev,
      changeDueDateDialog: false,
      currentDueDate: new Date(),
      newDueDate: new Date(),
    }));
  };

  return (
    <>
      {/* filter Dialog */}
      <CustomLoader show={getSchoolLoading || getSchoolIsFetching} />
      <AccountStatusDialog
        open={state.openChangeAccountStatus}
        onClose={() =>
          setState((prev) => ({ ...prev, openChangeAccountStatus: false }))
        }
        refetch={() => {
          setEnabled(true); //for calling the api again
        }}
        schoolDocId={state.selectedItemSchoolDocId}
        status={state.currentAccountStatus}
      />
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Institute List"
      />

      <ChangeDuesDateDialog
        state={state}
        setState={setState}
        handleCloseChangeDueDateDialog={handleCloseChangeDueDateDialog}
        handleRefetch={() => {
          setEnabled(true); //for calling the api again
        }}
      />

      <Container maxWidth="xl" disableGutters={isMobile ? true : false}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <InstituteListFilter mutate={handleFilter} />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <ExportButtonGroup
              showExcel
              handleClickExcel={() => setOpenCheckBox(true)}
            />
          </Box>
        </Stack>
        <Box sx={{ mt: 2 }}>
          {/* <CommonTable1
            columns={columns}
            data={state.dataList}
            maxHeight="70vh"
          /> */}
          <InfiniteScrollTable
            columns={columns}
            maxHeight="60vh"
            apiUrl={getSchool}
            apiParams={
              state.filterData
                ? state.filterData
                : { from: new Date(), to: new Date() }
            }
            setState={setState}
            enabled={enabled}
            setEnabled={setEnabled}
            filterRefresh={false} //use this to refresh the table on filter change
          />
        </Box>
      </Container>
    </>
  );
};

export default InstituteList;
