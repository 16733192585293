import { axiosInstance } from "../axios";
const school_route = `${process.env.REACT_APP_ORG_BASE}/school`;

const school_enquiry_route = `${process.env.REACT_APP_ORG_BASE}/schoolEnquiryRoutes`;
const baseUrl = `${process.env.REACT_APP_ORG_BASE}`;

export const getSchoolCounts = () => {
  return axiosInstance.get(`${school_route}/getSchoolCounts`);
};
export const getStaffCounts = () => {
  return axiosInstance.get(`${school_route}/getStaffCounts`);
};
export const getTotalEnquiry = () => {
  return axiosInstance.get(`${school_route}/getTotalEnquiry`);
};
export const getDistinctActiveSessionDropdown = (params) => {
  return axiosInstance.get(`${school_route}/getDistinctActiveSessionDropdown`, {
    params,
  });
};
export const getSchoolEnquiry = (params) => {
  return axiosInstance.get(`${school_enquiry_route}/getSchoolEnquiry`, {
    params,
  });
};
export const changeStatusOfSchoolEnquiry = (body) => {
  return axiosInstance.put(
    `${school_enquiry_route}/changeStatusOfSchoolEnquiry`,
    body
  );
};

/// Get Suggession by Search
export const getSchoolByTextSearch = (params) => {
  return axiosInstance.get(`${school_route}/getSchoolByTextSearch`, { params });
};
//// get oneSchool by schoolDocId
export const getSchool = (params) => {
  return axiosInstance.get(`${school_route}/getSchool`, { params });
};
export const getAllSessions = (params) => {
  return axiosInstance.get(`${school_route}/getAllSessions`, {
    params,
  });
};
export const createSchoolEnquiry = (body) => {
  return axiosInstance.post(
    `${school_enquiry_route}/createSchoolEnquiry`,
    body
  );
};
export const updateSchoolEnquiry = (body) => {
  return axiosInstance.put(`${school_enquiry_route}/updateSchoolEnquiry`, body);
};
export const createSchoolEnquiryThread = (body) => {
  return axiosInstance.post(
    `${school_enquiry_route}/createSchoolEnquiryThread`,
    body
  );
};
export const addSession = (body) => {
  return axiosInstance.post(`${school_route}/addSession`, body);
};

export const updatePaymentStatus = (body) => {
  return axiosInstance.put(`${school_route}/paymentClearedStatus`, body);
};
export const updateVerificationStatus = (body) => {
  return axiosInstance.put(`${school_route}/changeVerifiedSchoolStatus`, body);
};
export const updateAccountStatus = (body) => {
  return axiosInstance.put(`${school_route}/changeSchoolStatus`, body);
};
export const updateSubscriptionType = (body) => {
  return axiosInstance.put(`${school_route}/updateSubscriptionType`, body);
};

export const payInstallment = (body) => {
  return axiosInstance.post(
    `${school_route}/paySchoolLincenceInstallment?installment=1`,
    body
  );
};
export const updateTransaction = (body) => {
  return axiosInstance.put(
    `${school_route}/changeStatusTransactionPayment`,
    body
  );
};

export const editEnquiryThread = (body) => {
  return axiosInstance.put(`${school_enquiry_route}/editEnquiryThread`, body);
};
export const deleteEnquiryThread = (id) => {
  return axiosInstance.delete(
    `${school_enquiry_route}/deleteEnquiryThread/${id}`
  );
};
export const getEkalsutraStaff = (params) => {
  return axiosInstance.get(`${baseUrl}/esStaff/getEkalsutraStaff`, {
    params,
  });
};
export const addAssignee = (body) => {
  return axiosInstance.put(`${school_enquiry_route}/addAssignee`, body);
};
export const removeAssignee = (body) => {
  return axiosInstance.put(`${school_enquiry_route}/removeAssignee`, body);
};

/// Date 08/12/2023
/// Creating New axiousInstaces
